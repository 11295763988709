import React from "react";

import {
  Paper,
  Grid,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import FeatureBlogCard from "../../components/FeaturedBlogCard";

import CustomerBlogCover from "../../assets/blogs/customer_eng/img_1.jpg";
import PriceSplitCover from "../../assets/blogs/pricesplit/img_1.jpg";
import ProductOptimize from "../../assets/blogs/productcontent/img_1.png";

import GeniusGateLogo from "../../assets/genius_blog_icon.png";

const featuredBlogData = [
  {
    header: "Customer Engagement Analytics: Improve Data Accuracy",
    coverImage: CustomerBlogCover,
    blogDescription:
      "Customer Engagement Analytics leverages data to understand customer interactions, enhancing personalization and loyalty. By analyzing key metrics, businesses can improve experiences, address issues.",
    blogDate: "June 19, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "GeniusGate",
    linkTo: "/learn/customer-engagement-analytics/",
  },
  {
    header:
      "How to Master Pricing Split Testing for Optimal Results: Complete Guide",
    coverImage: PriceSplitCover,
    blogDescription:
      "Finding the perfect price is challenging for any product. Pricing split testing helps balance cost and value, maximizing revenue. Learn how to use data-driven A/B testing to optimize your pricing strategy effectively.",
    blogDate: "June 21, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "GeniusGate",
    linkTo: "/learn/pricing-split-testing/",
  },
  {
    header: "Top 7 Product Content Optimization [Tips] for [eCommerce]",
    coverImage: ProductOptimize,
    blogDescription:
      "Discover the magic of product content optimization. Learn how to enhance visibility, build trust, and boost sales with engaging descriptions, high-quality images, and strategic SEO for your online store.",
    blogDate: "June 21, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "GeniusGate",
    linkTo: "/learn/product-content-optimization/",
  },
];

const NewBlog = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box sx={{ mt: 15 }}>
        <Paper elevation={0} sx={{ backgroundColor: "#1A1D2F" }}>
          <Divider
            sx={{
              height: "0.8px",
              width: "100%",
              backgroundImage:
                "linear-gradient(270deg, rgba(255, 236, 200, 0) 0%, #FFECC8 50%, rgba(255, 236, 200, 0) 100%)",
            }}
          />
          <Box sx={{ pt: 8, pb: 8 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: isMobile ? "30px" : "40px",
                fontWeight: 700,
                lineHeight: isMobile ? "36px" : "48px",
                letterSpacing: "-0.5px",
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              Our Recent Posts
            </Typography>
            <Grid container spacing={2} sx={{ mt: 5 }}>
              {featuredBlogData.map((blog) => (
                <Grid item xs={12} sm={6} md={4} key={blog.header}>
                  <FeatureBlogCard
                    header={blog.header}
                    coverImage={blog.coverImage}
                    blogDescription={blog.blogDescription}
                    blogDate={blog.blogDate}
                    writerAvtar={blog.writerAvtar}
                    writerName={blog.writerName}
                    linkTo={blog.linkTo}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default NewBlog;
