import React from "react";
import { Button, Box } from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { keyframes } from "@emotion/react";

interface ButtonProps {
  text: string;
  onClick?: (data?: any) => void;
  type?: "button" | "submit" | "reset";
  showIcon?: boolean;
  radius: string;
  variant: "contained" | "outlined" | "text";
  textColor?: string;
  outlinedColor?: string;
}

const clickAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
`;

const CustomButton = ({
  text,
  onClick,
  type,
  showIcon,
  radius,
  variant,
  textColor,
  outlinedColor,
}: ButtonProps) => {
  const variantOutlined = outlinedColor ?? "#FFB331";

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const button = event.currentTarget;
    button.style.animation = `${clickAnimation} 0.2s ease-in-out`;
    if (onClick) {
      onClick();
    }
    setTimeout(() => {
      button.style.animation = "";
    }, 200);
  };

  return (
    <div className="button">
      <Box>
        <Button
          variant={variant}
          sx={{
            background:
              variant === "outlined"
                ? "transparent"
                : "linear-gradient(90deg, #F7CF67 0%, #FFA075 100%)",
            padding: "12px",
            fontWeight: "bold",
            borderRadius: "12px",
            color: textColor ? textColor : "#000000",
            textTransform: "none",
            border:
              variant === "outlined" ? `1px solid ${variantOutlined}` : "none",
            "&:hover": {
              background:
                variant === "outlined"
                  ? "transparent"
                  : "linear-gradient(90deg, #F7CF67 0%, #FFA075 100%)",
              border:
                variant === "outlined"
                  ? `1px solid ${variantOutlined}`
                  : "none",
            },
          }}
          onClick={handleClick}
          type={type}
        >
          {text}
          {showIcon && <KeyboardArrowRight />}
        </Button>
      </Box>
    </div>
  );
};

export default CustomButton;
