import React from "react";
import { styled } from "@mui/material/styles";

interface GradientTextProps {
  text: string;
}

const GradientText = styled("span")({
  background: "linear-gradient(177.7deg, #FFFFFF 2.03%, #7C7B7B 88.16%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
  display: "inline-block",
});

const CustomText = ({ text }: GradientTextProps) => {
  return <GradientText>{text}</GradientText>;
};

export default CustomText;
