import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Container,
  Stack,
} from "@mui/material";
import { motion } from "framer-motion";

import CustomButton from "../../components/CustomButton";
import EmailForm from "../../components/EmailForm";

import FormBackground from "../../assets/form_bg.png";
import { logEvent } from "../../analytics";
import CustomText from "../../components/CustomText";

import HeroDashboard from "../../assets/hero_dashboard.svg";
import Navbar from "../../components/Navbar";

const SignUpHero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    logEvent("Button", "User Waitlist Button Clicked");
  };
  const handleClose = () => setOpen(false);

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = FormBackground;
    img.onload = () => setBackgroundLoaded(true);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    height: "auto",
    background: backgroundLoaded
      ? `url(${FormBackground}) no-repeat center center`
      : "linear-gradient(to bottom, #0F111A 0%, #232634 100%)",
    backgroundSize: "cover",
    borderRadius: 10,
    padding: theme.spacing(3),
    margin: 0,
    border: "none",
    transition: "background 0.5s ease-in-out",

    // Responsive styles for mobile screens
    "@media (max-width: 600px)": {
      width: "90%",
      padding: theme.spacing(2),
    },
  };

  return (
    <Box>
      <Navbar />
      <Paper sx={{ backgroundColor: "#1A1D2F" }}>
        <Container>
          <Grid container spacing={10} sx={{ pt: 10, pb: 10 }}>
            <Grid item xs={12} md={7}>
              <Stack spacing={2}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: isMobile ? "32px" : "42px",
                    color: "#ffffff",
                    fontWeight: 700,
                    lineHeight: "48px",
                  }}
                >
                  Transform Your Website with{" "}
                  <CustomText text="AI-Driven Optimization Insights" />
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Our platform offers continuous testing and optimization of
                  landing page elements, ensuring peak performance. Tailored for
                  Digital Marketers and Product Managers, it maximizes marketing
                  impact by seamlessly adjusting to user interactions and
                  trends.
                </Typography>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <EmailForm onClose={handleClose} />
                  </Box>
                </Modal>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box sx={{ pt: 5 }}>
                    <CustomButton
                      text="Join Waitlist!"
                      radius="12px"
                      onClick={handleOpen}
                      variant="contained"
                    />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <motion.img
                src={HeroDashboard}
                alt="GeniusGate Dashboard"
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 350, damping: 10 }} // Adjusted for smoother animation
                style={{ maxWidth: "100%", height: "auto" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              />{" "}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Box>
  );
};

export default SignUpHero;
