import React from "react";
import { Box, Grid, Typography, Container, Stack, Paper } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { motion } from "framer-motion";

import Feature1 from "../../assets/feature_1.svg";
import Feature2 from "../../assets/review.svg";
import Feature3 from "../../assets/ab_testing.svg";

import FeatureIcon from "../../assets/feature_icon.svg";

import CustomText from "../../components/CustomText";

const featureItems = [
  {
    heading: "Dynamic AI-Powered Dashboard",
    description:
      "Leverage AI-driven insights with our dynamic dashboard that provides real-time updates on key metrics like page views and user engagement. Perfect for making informed decisions swiftly.",
    image: Feature1,
    altText:
      "A staircase with highlighted steps and a large circle at the top, illustrating progress or a step-by-step process.",
  },
  {
    heading: "Intelligent A/B Testing Suite",
    description:
      "Enhance your content strategy with our AI-enhanced A/B testing tools. Perform detailed comparisons and make precise adjustments to optimize everything from web pages to social media posts.",
    image: Feature2,
    altText:
      "Two cards positioned on a line graph, indicating comparison and analysis of data points.",
  },
  {
    heading: "Comprehensive Content Integration",
    description:
      "Connect seamlessly with key marketing platforms and tools. Use insights from Google Analytics, and integrate Ahrefs or Semrush for deep SEO analysis. Our platform supports detailed evaluations of ads banners, social media posts, and more, ensuring every content piece resonates with your audience.",
    image: Feature3,
    altText:
      "A central icon with lines connecting to multiple smaller icons, representing a network.",
  },
];

const Features = () => {
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#1A1D2F",
          p: { xs: 2, sm: 4, md: 8 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
          }}
        >
          <Box
            sx={{
              border: "1px solid white",
              p: 2,
              display: "flex",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <Circle sx={{ display: "inline", color: "#FFD700" }} />
            <Typography
              display={"inline"}
              sx={{
                verticalAlign: "middle",
                ml: 1,
                color: "white",
              }}
            >
              Key Features Tailored for Digital Excellence
            </Typography>
          </Box>
        </Box>
        <Container>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            align="center"
            sx={{
              mt: 3,
              mb: 4,
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "2.5rem",
              },
              fontWeight: 700,
              lineHeight: {
                xs: "40px",
                sm: "48px",
                md: "56px",
              },
              color: "white",
            }}
          >
            <CustomText text="Streamline Leads, Conversions and Ads" />
          </Typography>
          {featureItems.map((item) => (
            <Grid
              container
              key={item.heading}
              sx={{
                my: 3,
                p: 2,
                backgroundColor: "rgba(61, 61, 61, 0.2)",
                borderRadius: 10,
                position: "relative",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 10,
                  padding: "1px", // This creates the border thickness
                  background:
                    "linear-gradient(180deg, rgba(169, 163, 194, 0.25) 0%, rgba(169, 163, 194, 0.15) 100%)",
                  mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  maskComposite: "exclude",
                  pointerEvents: "none",
                },
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  p: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 10,
                    mb: 2,
                  }}
                >
                  <Stack spacing={2}>
                    <img
                      src={FeatureIcon}
                      style={{
                        width: "40px",
                      }}
                      alt="Feature Icon"
                    />
                    <Typography
                      variant="h3"
                      sx={{
                        ml: 1,
                        color: "white",
                        fontWeight: 700,
                        fontSize: "22px",
                        lineHeight: "32px",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "white",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.img
                  src={item.image}
                  alt={item.altText}
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "auto",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 2 }}
                />
              </Grid>
            </Grid>
          ))}
        </Container>
      </Paper>
    </Box>
  );
};

export default Features;
