import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";

import { initGA, logPageView } from "./analytics";

import PreloadImages from "./PreloadImages";
import { router } from "./routes";

function App() {
  useEffect(() => {
    initGA();
    logPageView("Page View", "/", "Home Page View");
  }, []);

  return (
    <div className="App">
      <PreloadImages />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
