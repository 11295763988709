import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Container,
  Modal,
  Paper,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

import { Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import CustomButton from "./CustomButton";

import EmailForm from "./EmailForm";
import FormBackground from "../assets/form_bg.png";
import { logEvent } from "../analytics";

const Root = styled(Box)`
  background-color: #1a1d2f;
  background-image: radial-gradient(
    at center top,
    rgb(78 92 218 / 25%) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top;
  padding: 32px 0;
`;

const CustomPaper = styled(Paper)`
  background-color: transparent;
  box-shadow: none;
  padding: 32px;
`;

const Footer = () => {
  useEffect(() => {
    const img = new Image();
    img.src = FormBackground;
    img.onload = () => setBackgroundLoaded(true);
  }, []);

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    logEvent("Button", "User Waitlist Button Clicked");
  };
  const handleClose = () => setOpen(false);

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    height: "auto",
    background: backgroundLoaded
      ? `url(${FormBackground}) no-repeat center center`
      : "linear-gradient(to bottom, #0F111A 0%, #232634 100%)",
    backgroundSize: "cover",
    borderRadius: 10,
    padding: theme.spacing(3),
    margin: 0,
    border: "none",
    transition: "background 0.5s ease-in-out",
    // Responsive styles for mobile screens
    "@media (max-width: 600px)": {
      width: "90%",
      padding: theme.spacing(2),
    },
  };

  return (
    <Box>
      <Root>
        <CustomPaper
          sx={{
            backgroundColor: "#1A1D2F",
            pt: { xs: 2, sm: 4, md: 8 },
            color: "#ffffff",
          }}
        >
          <Container>
            <Stack spacing={3}>
              <Typography
                variant="body1"
                sx={{ width: { xs: "100%", sm: "80%", md: "60%", lg: "35%" } }}
              >
                Experience the power of next-generation AI with GeniusGate to
                elevate your digital content strategy.
              </Typography>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <EmailForm onClose={handleClose} />
                </Box>
              </Modal>

              <CustomButton
                variant="contained"
                onClick={handleOpen}
                text="Get early access"
                radius="20px"
              />
            </Stack>
            <Box sx={{ mt: 5 }}>
              <hr />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color="#9B96B0"
              >
                <Typography variant="body2">
                  Copyright © 2024 GeniusGate
                </Typography>
                <Box>
                  <IconButton>
                    <a
                      href="https://www.instagram.com/geniusgateai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram
                        sx={{
                          color: "#9B96B0",
                        }}
                      />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      href="https://www.youtube.com/@GeniusGateAI"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YouTube
                        sx={{
                          color: "#9B96B0",
                        }}
                      />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      href="https://www.linkedin.com/company/genius-gate/about/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedIn
                        sx={{
                          color: "#9B96B0",
                        }}
                      />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      href="https://twitter.com/GeniusGate"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter
                        sx={{
                          color: "#9B96B0",
                        }}
                      />
                    </a>
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Container>
        </CustomPaper>
      </Root>
    </Box>
  );
};

export default Footer;
