// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  useMediaQuery,
  Divider,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import Footer from "./Footer";
import Navbar from "./Navbar";
import FaqCard from "./FaqCard";

interface BlogContentPageProps {
  header: string;
  coverImage: string;
  blogDate: string;
  readingTime: string;
  tableofContent: { id: string; title: string }[];
  children: React.ReactNode;
  blogUrl: string;
  faqs?: { question: string; answer: string }[];
  imageAltText?: string;
}

const Sidebar = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: theme.spacing(8),
  height: "calc(100vh - 64px)",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    display: "none", // Hide sidebar on small devices
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  color: "#D7DCFB",
  lineHeight: "30px",
}));

const SocialsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  color: "3D3D3D",
}));

const BlogPage: React.FC<BlogContentPageProps> = ({
  header,
  coverImage,
  tableofContent,
  children,
  blogDate,
  readingTime,
  blogUrl,
  faqs,
  imageAltText,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const encodedTitle = encodeURIComponent(header);
  const encodedUrl = encodeURIComponent(blogUrl);

  const shareOnLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
    window.open(linkedInUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    window.open(facebookUrl, "_blank");
  };

  return (
    <>
      <Navbar />
      <Paper sx={{ backgroundColor: "#1A1D2F", pt: 8 }}>
        <Container sx={{ pt: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Sidebar sx={{ mt: 27 }}>
                <SocialsBox>
                  <Typography
                    variant="body1"
                    sx={{ color: "#5A7184", fontWeight: 700, mt: "15px" }}
                  >
                    Share
                  </Typography>
                  <IconButton
                    aria-label="linkedln"
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#3D3D3D",
                      },
                    }}
                    onClick={shareOnLinkedIn}
                  >
                    <LinkedInIcon sx={{ fontSize: 30, color: "#5A7184" }} />
                  </IconButton>
                  <IconButton
                    aria-label="twitter"
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#3D3D3D",
                      },
                    }}
                    onClick={shareOnTwitter}
                  >
                    <TwitterIcon sx={{ fontSize: 30, color: "#5A7184" }} />
                  </IconButton>
                  <IconButton
                    aria-label="instagram"
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#3D3D3D",
                      },
                    }}
                    onClick={shareOnFacebook}
                  >
                    <FacebookIcon sx={{ fontSize: 30, color: "#5A7184" }} />
                  </IconButton>
                </SocialsBox>
                <Divider sx={{ backgroundColor: "#3D3D3D" }} />
                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#D7DCFB" }}
                  >
                    Table of Contents
                  </Typography>
                  <Paper elevation={0} sx={{ backgroundColor: "#1A1D2F" }}>
                    <List
                      component="nav"
                      aria-label="table of contents"
                      sx={{ padding: 1.5 }}
                    >
                      {tableofContent.map((item) => (
                        <ListItemButton
                          component="a"
                          href={`#${item.id}`}
                          key={item.id}
                          sx={{ padding: "0" }}
                        >
                          <ListItemText
                            primary={item.title}
                            sx={{ color: "#D7DCFB" }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Paper>
                </Box>
              </Sidebar>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: isMobile ? "40px" : "56px",
                  fontWeight: 700,
                  lineHeight: isMobile ? "50px" : "64px",
                  letterSpacing: "-1.5px",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                {header}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography variant="body1" sx={{ color: "#777DA4", mt: 2 }}>
                  {blogDate} 🟠 {readingTime}
                </Typography>
              </Box>

              <Box sx={{ mt: 3, textAlign: "center" }}>
                <img
                  src={coverImage}
                  alt={imageAltText}
                  style={{ maxWidth: "80%", height: "auto" }}
                />
              </Box>
              <ContentBox>{children}</ContentBox>
              {faqs && <FaqCard faqsData={faqs} />}
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Footer />
    </>
  );
};

export default BlogPage;
