import React from "react";

import { Box, Typography, Paper, Stack } from "@mui/material";

interface IntegrationCardProps {
  name: string;
  logoSrc: string;
}

const IntegrationCard = ({ name, logoSrc }: IntegrationCardProps) => {
  return (
    <div className="integration_card">
      <Box>
        <Paper
          sx={{
            p: 4,
            backgroundColor: "rgba(61, 61, 61, 0.2)",
            borderRadius: 10,
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 10,
              padding: "1px", // This creates the border thickness
              background:
                "linear-gradient(180deg, rgba(169, 163, 194, 0.25) 0%, rgba(169, 163, 194, 0.15) 100%)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              maskComposite: "exclude",
              pointerEvents: "none",
            },
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
                src={logoSrc}
                alt={name}
              />
            </Box>

            <Typography
              variant="body1"
              align="center"
              sx={{
                fontWeight: 700,
                color: "#ffffff",
                fontSize: "24px",
                lineHeight: "32px",
              }}
            >
              {name}
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </div>
  );
};

export default IntegrationCard;
