import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";

import SignUpHero from "./SignUpHero";
import TargetHero from "./TargetHero";
import Benefits from "./Benefits";
import Features from "./Features";
import Integration from "./Integration";
import Footer from "../../components/Footer";
import ProductDetails from "./ProductDetails";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "../../background.css";

const DotContainer = () => (
  <div className="dot-container">
    {[...Array(30)].map((_, index) => (
      <div key={index} className="dot"></div>
    ))}
  </div>
);

const Home = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <Box>
      <Helmet>
        <meta
          name="description"
          content="Boost lead conversion with GeniusGate's AI-driven A/B testing. 
    Optimize campaigns with detailed analytics, seamless integrations, 
    and continuous improvements."
        />
        <title>AI Marketing Tool - A/B Testing Software | GeniusGate</title>
        <link rel="canonical" href="https://geniusgate.ai/" />
        <link rel="alternate" href="https://geniusgate.ai/" hrefLang="en-us" />
      </Helmet>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={variants}
      >
        <Box position="relative">
          <DotContainer />
          <SignUpHero />
        </Box>
        <Box position="relative">
          <DotContainer />
          <ProductDetails />
        </Box>
        <Box position="relative">
          <DotContainer />
          <Features />
        </Box>
        <Box position="relative">
          <DotContainer />
          <Benefits />
        </Box>
        <Box position="relative">
          <DotContainer />
          <TargetHero />
        </Box>
        <Box position="relative">
          <DotContainer />
          <Integration />
        </Box>
        <Footer />
      </motion.div>
    </Box>
  );
};

export default Home;
