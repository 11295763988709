import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  Paper,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Stack,
  Container,
} from "@mui/material";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BestBlogCard from "../../components/BestBlogCard";

import FeatureBlogCover from "../../assets/blogs/aov/aov_1.jpg";
//import BlogLine from "../../assets/blog_line_bg.svg";

import FeaturedBlog from "./FeaturedBlog";
import CustomButton from "../../components/CustomButton";
import GeniusGateLogo from "../../assets/genius_blog_icon.png";
import NewBlog from "./NewBlog";

const popularTagsName = ["AB Testing & Analytics", "Conversion Optimization"];

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Navbar />
      <Box>
        <Helmet>
          <link rel="canonical" href="https://geniusgate.ai/learn/" />
          <link
            rel="alternate"
            href="https://geniusgate.ai/learn/"
            hrefLang="en-us"
          />
          <title>Insights on AI, Tech, and Business Growth | GeniusGate</title>
          <meta
            name="description"
            content="Explore our blog for expert insights on AI, technology, and business strategies. Stay updated with trends and innovations that drive success at GeniusGate."
          />
        </Helmet>
        <Paper
          sx={{
            backgroundColor: "#1A1D2F",
            pt: 10,
          }}
          elevation={0}
        >
          <Container>
            {/*<img src={BlogLine} alt="Blog Line" style={{ width: "100%" }} /> */}
            <Box sx={{ pb: 10 }}>
              <Stack spacing={4}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: isMobile ? "40px" : "56px",
                    fontWeight: 700,
                    lineHeight: isMobile ? "50px" : "64px",
                    letterSpacing: "-1px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  GeniusGate Blog
                </Typography>
                <Stack
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="center"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#D7DCFB", pt: 1.5 }}
                  >
                    Popular tags:
                  </Typography>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={2}
                    justifyContent="center"
                    spacing={2}
                  >
                    {popularTagsName.map((tag) => (
                      <CustomButton
                        text={tag}
                        variant="outlined"
                        radius="10px"
                        textColor="#ffffff"
                        outlinedColor="#ffffff"
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Box>
              <BestBlogCard
                header="What is Average Order Value (AOV)"
                coverImage={FeatureBlogCover}
                blogDescription="Unlock the power of Average Order Value (AOV) to maximize revenue and profitability by understanding customer spending habits and implementing strategic tactics to boost each order's value."
                blogDate="June 19, 2024"
                writerAvtar={GeniusGateLogo}
                writerName="GeniusGate"
                linkTo="/learn/aov-meaning/"
              />
            </Box>
            <FeaturedBlog />
            <NewBlog />
          </Container>
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default Blogs;
