import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Tabs,
  Tab,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";

import WebsiteAnalysis from "../../assets/product_website_analysis.svg";
import GraphicsAnalysis from "../../assets/product_graphics_analysis.svg";
import SalesAnalysis from "../../assets/product_sales_analysis.svg";
import ProductDetailsCard from "../../components/ProductDetailsCard";
import CustomText from "../../components/CustomText";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ProductDetailsData = [
  {
    productHeader: "Optimize Website Copy to Boost Conversions",
    productSubtext:
      "Leveraging powerful and advanced AI, we analyse, optimize, and fine-tune your website copy. Our custom integrated keyword research tool combined with content testing capabilities allow you to evaluate different versions of your text, ensuring it resonates with your audience. This process guarantees that every word contributes to higher engagement and conversion rates. Make your message compelling and clear with our intelligent copy analysis tool.",
    productImage: WebsiteAnalysis,
    productCTA: "Enhance Your Website Copy – Join the Waitlist",
  },
  {
    productHeader: "Elevate Your Graphics with Targeted AI Analysis",
    productSubtext:
      "Visual appeal is key to capturing your audience’s attention. With GeniusGate, our AI tool evaluates and optimizes your graphics copy, experimenting with different copy and placements to find what works best. Our advanced AI system ensures that your visuals are not only eye-catching, but also contribute to improving your goal metrics.",
    productImage: GraphicsAnalysis,
    productCTA: "Boost Your Visuals – Join the Waitlist",
  },
  {
    productHeader: "Refine Your Sales Copy for More Leads",
    productSubtext:
      "Drive your sales with compelling ad copies, whether on Facebook, LinkedIn, Twitter, or shopping ads. GeniusGate transforms your marketing copy to speak directly to your customers' needs. Our system evaluates your current sales messages and tests multiple variations to identify the most effective version, executing the perfect ad. Our AI insights help you craft persuasive content that attracts attention and converts visitors into paying customers.",
    productImage: SalesAnalysis,
    productCTA: "Craft Sales Copy That Works – Join the Waitlist",
  },
];

const CustomTabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4, color: "#ffffff" }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const Root = styled(Box)`
  background-color: #1a1d2f;
  background-image: radial-gradient(
    at center top,
    rgb(78 92 218 / 25%) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: top;
  padding: 32px 0;
`;

const CustomPaper = styled(Paper)`
  background-color: transparent;
  box-shadow: none;
  padding: 32px;
`;

const CustomTab = styled((props: any) => <Tab {...props} />)`
  &:hover {
    color: #ffd9be;
  }
  &.Mui-selected {
    color: #ffd9be;
  }
  color: #ffffff;
  text-transform: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin: 20px;
`;

const ProductDetails: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const itemLabel = {
    "Optimize Website Copy to Boost Conversions": "Website Analysis",
    "Elevate Your Graphics with Targeted AI Analysis": "Graphics Analysis",
    "Refine Your Sales Copy for More Leads": "Sales Analysis",
  };

  return (
    <Root>
      <Container>
        <CustomPaper>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
              fontWeight: 700,
              lineHeight: { xs: "40px", sm: "48px", md: "56px" },
            }}
          >
            <CustomText text="Learn More About Our Product" />
          </Typography>
          {isMobile ? (
            <Box sx={{ mt: 5 }}>
              <Grid container spacing={2}>
                {ProductDetailsData.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <ProductDetailsCard {...item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="product tabs"
              >
                {ProductDetailsData.map((item, index) => {
                  const label =
                    itemLabel[item.productHeader as keyof typeof itemLabel];
                  return (
                    <CustomTab
                      label={label}
                      {...a11yProps(index)}
                      key={index}
                    />
                  );
                })}{" "}
              </Tabs>
              {ProductDetailsData.map((item, index) => (
                <CustomTabPanel value={value} index={index} key={index}>
                  <ProductDetailsCard {...item} />
                </CustomTabPanel>
              ))}
            </Box>
          )}
        </CustomPaper>
      </Container>
    </Root>
  );
};

export default ProductDetails;
