import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import BlogContentPage from "../../../../components/BlogContentPage";
import SequentialTestingContent from "./SequentialTesting.mdx";

import BlogCover from "../../../../assets/blogs/sequential/img_1.jpg";

const tableofContent = [
  { id: "introduction", title: "Introduction to Sequential Testing " },
  {
    id: "importance",
    title: "The Importance of Sequential Testing in Risk Management ",
  },
  { id: "application", title: "Modern Applications of Sequential Analysis" },
  { id: "fundamentals", title: "The Fundamentals of Sequential Testing" },
  { id: "concepts", title: "Statistical Concepts Behind Sequential Testing" },
  {
    id: "objections",
    title: "Addressing Common Objections to Sequential Testing",
  },
  { id: "casestudies", title: "Implementations and Case Studies" },
  {
    id: "bestpractices",
    title:
      "Best Practices for Configuring and Implementing Sequential Testing ",
  },
  {
    id: "implementations",
    title: "Cutting-edge Approaches: GrowthBook & Statsig's Implementations",
  },
  { id: "summing-it-up", title: "Summing It Up" },
];

const ArticleSequentialTesting = () => {
  const websiteHost = "https://geniusgate.ai";
  const blogRoute = "/learn/sequential-testing";
  const blogUrl = websiteHost + blogRoute;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="article_aov">
      <Helmet>
        <title>How to Choose a Sequential Testing Framework</title>
        <meta
          name="description"
          content="Understand how Sequential Testing simplifies A/B decisions with dynamic data monitoring. Optimize your testing processes with this reliable methodology. "
        />
        <link
          rel="canonical"
          href="https://geniusgate.ai/learn/sequential-testing/"
        />
        <link
          rel="alternate"
          href="https://geniusgate.ai/learn/sequential-testing/"
          hrefLang="en-us"
        />
      </Helmet>
      <BlogContentPage
        header="How to Choose a Sequential Testing Framework"
        coverImage={BlogCover}
        tableofContent={tableofContent}
        children={<SequentialTestingContent />}
        blogDate="June 18, 2024"
        readingTime="8 mins read"
        blogUrl={blogUrl}
      />
    </div>
  );
};

export default ArticleSequentialTesting;
