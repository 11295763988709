import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Box,
  Container,
  Typography,
  Modal,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "./CustomButton";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import EmailForm from "./EmailForm";

import FormBackground from "../assets/form_bg.png";

interface ProductDetailsCardProps {
  productHeader: string;
  productSubtext: string;
  productImage: string;
  productCTA: string;
}

const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({
  productHeader,
  productSubtext,
  productImage,
  productCTA,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }

    const img = new Image();
    img.src = FormBackground;
    img.onload = () => setBackgroundLoaded(true);
  }, [controls, inView]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    height: "auto",
    background: backgroundLoaded
      ? `url(${FormBackground}) no-repeat center center`
      : "linear-gradient(to bottom, #0F111A 0%, #232634 100%)",
    backgroundSize: "cover",
    borderRadius: 10,
    padding: theme.spacing(3),
    margin: 0,
    border: "none",
    transition: "background 0.5s ease-in-out",

    // Responsive styles for mobile screens
    "@media (max-width: 600px)": {
      width: "90%",
      padding: theme.spacing(2),
    },
  };

  return (
    <div className="product_details_card">
      <Box>
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6}>
              <motion.div
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
              >
                <Stack spacing={3}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: isMobile ? "30px" : "40px",
                      color: "#ffffff",
                      fontWeight: 400,
                      lineHeight: "48px",
                    }}
                  >
                    {productHeader}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#E0E0E0", lineHeight: "28px" }}
                  >
                    {productSubtext}
                  </Typography>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <EmailForm onClose={handleClose} />
                    </Box>
                  </Modal>
                  <CustomButton
                    text={productCTA}
                    radius="12px"
                    variant="contained"
                    onClick={handleOpen}
                  />
                </Stack>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <motion.img
                src={productImage}
                alt={productHeader}
                style={{ maxWidth: "100%", height: "auto" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 2 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ProductDetailsCard;
