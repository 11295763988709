import { useEffect } from "react";

const imagesToPreload = [
  "./assets/genius_gate_logo.svg",
  "./assets/footer.svg",
  "./assets/ab_testing.svg",
  "./assets/analysis.svg",
  "./assets/arrow_vector.svg",
  "./assets/feature_1.svg",
  "./assets/feature_icon.svg",
  "./assets/flex_1.svg",
  "./assets/flex_2.svg",
  "./assets/flex_3.svg",
  "./assets/flex_4.svg",
  "./assets/flex_5.svg",
  "./assets/flex_6.svg",
  "./assets/form_bg.png",
  "./assets/Line.svg",
  "./assets/review.svg",
  // Add more images as needed
];

const PreloadImages = () => {
  useEffect(() => {
    for (const image of imagesToPreload) {
      const img = new Image();
      img.src = image;
    }
  }, []);

  return null; // This component does not render anything
};

export default PreloadImages;
