import React from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  Paper,
  Stack,
  Avatar,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import CustomButton from "./CustomButton";

interface FeaturedBlogCardProps {
  header: string;
  coverImage: string;
  blogDate: string;
  blogDescription: string;
  writerAvtar: string;
  writerName: string;
  linkTo: string;
}

const FeatureBlogCard = ({
  header,
  coverImage,
  blogDate,
  blogDescription,
  writerAvtar,
  writerName,
  linkTo,
}: FeaturedBlogCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Box>
        <Paper
          sx={{
            backgroundColor: "rgba(61, 61, 61, 0.2)",
            borderRadius: 7,
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 7,
              padding: "1px", // This creates the border thickness
              background:
                "linear-gradient(180deg, rgba(169, 163, 194, 0.25) 0%, rgba(169, 163, 194, 0.15) 100%)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              maskComposite: "exclude",
              pointerEvents: "none",
            },
            p: 2,
          }}
          elevation={0}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <img
                src={coverImage}
                alt={`${header} cover`}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  maxWidth: "100%",
                  maxHeight: "400px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? "16px" : "24px",
                    fontWeight: 700,
                    lineHeight: isMobile ? "24px" : "32px",
                    letterSpacing: "-0.5px",
                    color: "#ffffff",
                  }}
                >
                  {header}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    color: "#8f8f8f",
                  }}
                >
                  {blogDescription}
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar src={writerAvtar} alt={writerName} />
                    <Box sx={{ pt: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#ffffff", fontWeight: 700 }}
                      >
                        {writerName}
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography variant="body1" sx={{ color: "#777DA4" }}>
                    {blogDate}
                  </Typography>
                </Stack>

                <Divider
                  sx={{
                    height: "0.8px",
                    width: "100%",
                    backgroundImage:
                      "linear-gradient(270deg, rgba(255, 236, 200, 0) 0%, #FFECC8 50%, rgba(255, 236, 200, 0) 100%)",
                  }}
                />
                <Link to={linkTo}>
                  <CustomButton
                    text="Continue Reading"
                    radius="20px"
                    variant="outlined"
                    textColor="#FFB331"
                    showIcon={true}
                  />
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
};

export default FeatureBlogCard;
