import React from "react";

import GraphGif from "../../assets/increasing_graph.webp";
import StreamLineGif from "../../assets/streamline.webp";
import ContinuousGif from "../../assets/continuous.webp";

import styled from "@emotion/styled";

import { Grid, Typography, Container, Stack, Box, Paper } from "@mui/material";
import CustomText from "../../components/CustomText";

import { Circle } from "@mui/icons-material";

const benefitContents = [
  {
    heading: "Increased conversions",
    animation: GraphGif,
    altText:
      "A bar graph with an upward trending arrow, symbolizing growth and increased performance.",
    width: "60%",
    description:
      "Boost your website's conversion rates with AI-driven insights and optimizations that continually learn and adapt to your audience's preferences.",
  },
  {
    heading: "Streamlined Workflow",
    animation: StreamLineGif,
    altText:
      "A small square connected to a document featuring a shield icon, indicating security or protection of information.",
    width: "100%",
    description:
      "Simplify your marketing efforts with automated A/B testing, data-driven recommendations, and seamless CRM integration for a consistently optimized strategy.",
  },
  {
    heading: "Continuous Improve",
    animation: ContinuousGif,
    altText:
      "A set of yellow concentric circles, representing focus, target, or precision.",
    width: "50%",
    description:
      "Stay ahead of the competition with continuous learning and adaptation, ensuring long-term success for your business in a rapidly evolving digital landscape.",
  },
];

const Root = styled(Box)`
  background-color: #1a1d2f;
  background-image: radial-gradient(
    at center top,
    rgb(78 92 218 / 25%) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: top;
  padding: 32px 0;
`;

const CustomPaper = styled(Paper)`
  background-color: transparent;
  box-shadow: none;
  padding: 32px;
`;

const Benefits = () => {
  return (
    <Box>
      <Root>
        <CustomPaper
          elevation={0}
          sx={{
            backgroundColor: "#1A1D2F",
            p: { xs: 2, sm: 4, md: 8 }, // Responsive padding
            color: "#ffffff",
            m: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: 3,
            }}
          >
            <Box
              sx={{
                border: "1px solid white",
                p: 2,
                display: "flex",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              <Circle sx={{ display: "inline", color: "#FFD700" }} />
              <Typography
                display={"inline"}
                sx={{
                  verticalAlign: "middle",
                  ml: 1,
                  color: "white",
                }}
              >
                GeniusGate Benefits
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Typography
              variant="h2"
              component="h2"
              align="center"
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.5rem",
                },
                fontWeight: 700,
                lineHeight: {
                  xs: "40px",
                  sm: "48px",
                  md: "56px",
                },
              }}
            >
              <CustomText text="How GeniusGate Elevates Your Marketing Efforts" />
            </Typography>
            <Container>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                justifyContent="center"
                sx={{
                  mt: { xs: 3, md: 5 },
                  display: "flex", // Ensures flexbox layout
                  alignItems: "stretch", // Stretches items to fill the container
                }}
              >
                {benefitContents.map((benefit, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={benefit.heading}
                    sx={{
                      display: "flex", // Uses flexbox for each item
                    }}
                  >
                    <Paper
                      elevation={1}
                      sx={{
                        backgroundColor: "rgba(166, 216, 221, 0.05)",
                        borderRadius: 10,
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          borderRadius: 10,
                          padding: "1px", // This creates the border thickness
                          background:
                            "linear-gradient(180deg, rgba(169, 163, 194, 0.25) 0%, rgba(169, 163, 194, 0.15) 100%)",
                          mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          maskComposite: "exclude",
                          pointerEvents: "none",
                        },
                        p: {
                          xs: 2,
                          md: 3,
                        },
                        flexGrow: 1, // Allows the Paper to fill the grid item
                        display: "flex",
                        flexDirection: "column", // Stacks content vertically
                        justifyContent: "space-between", // Distributes spacing between items
                      }}
                    >
                      <Stack spacing={4}>
                        <Box
                          sx={{
                            textAlign: "center",
                            p: {
                              xs: 3,
                              md: 5,
                            },
                            pt:
                              index === 1
                                ? {
                                    xs: 8,
                                    md: 10,
                                  }
                                : {
                                    xs: 3,
                                    md: 5,
                                  }, // Adding conditional padding for the second GIF
                          }}
                        >
                          <img
                            src={benefit.animation}
                            alt={benefit.altText}
                            style={{
                              width: "100%",
                              maxWidth: benefit.width,
                              height: "auto",
                            }}
                            className="responsive-gif"
                          />
                        </Box>
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: {
                              xs: "20px",
                              md: "25px",
                            },
                            fontWeight: 700,
                            lineHeight: {
                              xs: "30px",
                              md: "35px",
                            },
                            color: "#ffffff",
                          }}
                        >
                          {benefit.heading}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            my: 2,
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "rgba(204, 204, 204, 1)",
                          }}
                        >
                          {benefit.description}
                        </Typography>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </CustomPaper>
      </Root>
    </Box>
  );
};

export default Benefits;
