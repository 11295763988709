import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Stack,
  Paper,
  Modal,
  useTheme,
} from "@mui/material";
import ArrowIcon from "../../assets/arrow_vector.svg";
import CustomButton from "../../components/CustomButton";

import LineImg from "../../assets/Line.svg";
import DemoForm from "../../components/DemoForm";
import FormBackground from "../../assets/form_bg.png";

import { logEvent } from "../../analytics";
import CustomText from "../../components/CustomText";

const listItems = [
  {
    icon: ArrowIcon,
    text: "Gain unparalleled insights into your digital campaigns with detailed analytics on user engagement, the impact of your copy, and overall conversion rates.",
  },
  {
    icon: ArrowIcon,
    text: "Effortlessly design and execute A/B tests to optimize your website and campaign strategies for peak performance.",
  },
  {
    icon: ArrowIcon,
    text: "Harness the power of Ahrefs and Semrush for in-depth keyword and competitive analysis, ensuring you stay ahead of the curve in your market.",
  },
  {
    icon: ArrowIcon,
    text: "Enjoy smooth integration with platforms like Google Analytics and HubSpot CRM, leveraging powerful data and insights to enhance your marketing strategies.",
  },
];

const TargetHero = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
    logEvent("Button", "Demo Button Clicked");
  };

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = FormBackground;
    img.onload = () => setBackgroundLoaded(true);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    height: "auto",
    background: backgroundLoaded
      ? `url(${FormBackground}) no-repeat center center`
      : "linear-gradient(to bottom, #0F111A 0%, #232634 100%)",
    backgroundSize: "cover",
    borderRadius: 10,
    padding: theme.spacing(3),
    margin: 0,
    border: "none",
    transition: "background 0.5s ease-in-out",

    // Responsive styles for mobile screens
    "@media (max-width: 600px)": {
      width: "90%",
      padding: theme.spacing(2),
    },
  };

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#1A1D2F",
          p: { xs: 2, sm: 4, md: 6 },
          py: 3,
          color: "#ffffff",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
            },
            fontWeight: 700,
            lineHeight: {
              xs: "40px",
              sm: "48px",
              md: "56px",
            },
            pt: 4,
          }}
        >
          <CustomText text="Optimize Your Digital Strategy with GeniusGate" />
        </Typography>

        <Grid
          container
          spacing={8}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            px: { xs: 2, sm: 4, md: 8 },
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.5rem",
                  md: "2rem",
                },
                fontWeight: 700,
                lineHeight: "48px",
                pt: 5,
              }}
            >
              Why GeniusGate Stands Out
            </Typography>
            {listItems.map((item) => (
              <Box sx={{ mt: 2 }}>
                <Stack spacing={2} sx={{ my: 2 }}>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={item.icon}
                      alt="A star with grey colouring."
                      width="25"
                      height="25"
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#BFBFBF",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ))}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <DemoForm onClose={handleClose} />
              </Box>
            </Modal>
            <Box sx={{ mt: 3 }}>
              <CustomButton
                text="Request Your Demo Today"
                showIcon={true}
                radius="20px"
                onClick={handleOpen}
                variant="contained"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pt: 5,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={LineImg}
              alt="A dark background with intersecting, wavy lines in blue and orange, representing data or trends."
              style={{
                width: "100%",
                maxWidth: 400,
              }}
            />
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            lineHeight: "48px",
            textAlign: "center",
            color: "#ffffff",
            p: 5,
          }}
        >
          {" "}
          {""}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            lineHeight: "48px",
            textAlign: "center",
            color: "#ffffff",
            p: 5,
          }}
        >
          {" "}
          {""}
        </Typography>
      </Paper>
    </Box>
  );
};

export default TargetHero;
