import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Container,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GeniusGateLogo from "../assets/genius_gate_logo.svg";

const pages = [{ page: "Learn", link: "/learn/" }];

interface Props {
  showCTA?: boolean;
}

const Navbar = ({ showCTA = true }: Props) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (link?: string) => {
    setAnchorElNav(null);
    if (link) {
      setTimeout(() => {
        navigate(link);
      }, 150); // Delay to allow the menu to begin closing animation
    }
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#1A1D2F", color: "#D7DCFB" }}
      elevation={0}
    >
      <Container>
        <Toolbar disableGutters>
          <Link to="/" style={{ color: "#D7DCFB", textDecoration: "none" }}>
            <img src={GeniusGateLogo} alt="GeniusGate Logo" />
          </Link>
          <Box sx={{ flexGrow: { xs: 1, md: 0 } }} />
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu()}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  backgroundColor: "#1A1D2F",
                  color: "#D7DCFB",
                },
              }}
            >
              {pages.map(({ page, link }) => (
                <MenuItem key={link} onClick={() => handleCloseNavMenu(link)}>
                  <Link
                    to={link}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-start",
              ml: 30,
            }}
          >
            {pages.map(({ page, link }) => (
              <Link
                key={link}
                to={link}
                style={{
                  textDecoration: "none",
                  color: "#D7DCFB",
                }}
              >
                <Button
                  onClick={() => handleCloseNavMenu()}
                  sx={{
                    textTransform: "none",
                    color: "#D7DCFB",
                    marginLeft: 3,
                    marginRight: 3,
                    fontWeight: 500,
                    fontSize: "18px",
                  }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
        <Divider
          sx={{
            height: "0.8px",
            width: "100%",
            backgroundImage:
              "linear-gradient(270deg, rgba(255, 236, 200, 0) 0%, #FFECC8 50%, rgba(255, 236, 200, 0) 100%)",
          }}
        />
      </Container>
    </AppBar>
  );
};

export default Navbar;
