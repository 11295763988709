import ReactGA from "react-ga4";

const TRACKING_ID = "G-3PRK4F47P5";

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (hitType, pagePath, viewTitle) => {
  ReactGA.send({
    hitType: hitType,
    page: pagePath,
    title: viewTitle,
  });
};

export const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action,
    });
  }
};

export const logTiming = (category = "", variable = "", value = 0) => {
  if (category && variable && value) {
    ReactGA.timing({ category, variable, value });
  }
};
