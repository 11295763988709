import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";

const PROJECT_BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;
const BACKEND_KEY = process.env.REACT_APP_API_KEY as string;
const API_ENDPOINT = `${PROJECT_BACKEND_URL}/genius_gate/demo_query`;

interface DemoFormProps {
  onClose: () => void;
}

const emailValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  website: Yup.string().url("Invalid website URL"),
  interestInfo: Yup.string().required("Field is required"),
});

const DemoForm = ({ onClose }: DemoFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailValidationSchema),
    mode: "onSubmit",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmit = async (data: any) => {
    console.log(data);
    const request_data = {
      name: data.name,
      email: data.email,
      website: data.website,
      interest_info: data.interestInfo,
    };

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization-Token": BACKEND_KEY,
        },
        body: JSON.stringify(request_data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(`Request failed with status ${response.status}`);
      }
      const responseData = await response.json();
      if (responseData.status_code === "201") {
        console.log("Success:", responseData);
        setFormSubmitted(true);
      } else {
        console.log("Error:", responseData);
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.log("Error", error);
      setFormError(true);
      console.log(formError);
    }
  };

  return (
    <Box>
      <Stack spacing={3} alignItems="center">
        <Typography
          variant="h4"
          sx={{
            color: "#FFD700",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: 700,
            lineHeight: "48px",
          }}
        >
          Genius
          <span style={{ color: "#ffffff" }}>Gate</span>
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 1, color: "white" }}
        >
          <CloseIcon />
        </IconButton>
        {!formSubmitted && (
          <>
            <Typography
              variant="h4"
              sx={{
                color: "#ffffff",
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "40px",
                textAlign: "center",
              }}
            >
              Schedule a Demo
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
                textAlign: "center",
                color: "#A3A3A3",
              }}
            >
              Submit the info to schedule a demo and see firsthand how
              GeniusGate can enhance your marketing efforts.
            </Typography>
          </>
        )}
        {!formSubmitted ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={2}
              sx={{
                textAlign: "center",
                width: "400px",
                ml: { xs: 11, sm: 0, md: 0 },
              }}
            >
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    placeholder="Enter your Name"
                    sx={{
                      color: "#ffffff",
                      width: {
                        xs: "70%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    error={Boolean(errors.name)}
                    fullWidth
                  />
                )}
              />
              {errors.name && (
                <Typography color="error" variant="caption">
                  {errors.name.message}
                </Typography>
              )}
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    placeholder="Enter your Email"
                    sx={{
                      color: "#ffffff",
                      width: {
                        xs: "70%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    error={Boolean(errors.email)}
                    fullWidth
                  />
                )}
              />
              {errors.email && (
                <Typography color="error" variant="caption">
                  {errors.email.message}
                </Typography>
              )}
              <Controller
                name="website"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    placeholder="Enter your Website URL (optional)"
                    sx={{
                      color: "#ffffff",
                      width: {
                        xs: "70%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    error={Boolean(errors.website)}
                    fullWidth
                  />
                )}
              />
              {errors.website && (
                <Typography color="error" variant="caption">
                  {errors.website.message}
                </Typography>
              )}
              <Controller
                name="interestInfo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    placeholder="Let us know what are you hoping to achieve with GeniusGate"
                    sx={{
                      color: "#ffffff",
                      width: {
                        xs: "70%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    error={Boolean(errors.interestInfo)}
                    fullWidth
                    multiline
                    rows={4}
                  />
                )}
              />
              {errors.interestInfo && (
                <Typography color="error" variant="caption">
                  {errors.interestInfo.message}
                </Typography>
              )}
              <Box>
                <CustomButton
                  variant="contained"
                  type="submit"
                  text="Schedule Demo"
                  radius="20px"
                />
              </Box>
            </Stack>
          </form>
        ) : (
          <Typography
            variant="body1"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center",
              color: "#50C878",
            }}
          >
            Thank you for requesting a demo! One of our team members will be in
            touch soon to schedule a convenient time for your demo. We look
            forward to helping you optimize your digital marketing efforts!
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default DemoForm;
