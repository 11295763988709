import React from "react";

import { Box, Paper, Typography, Grid } from "@mui/material";

import CustomText from "../../components/CustomText";

import GoogleAnalytics from "../../assets/googleanalytics.svg";
import Semrush from "../../assets/semrush.svg";
import Canva from "../../assets/canva.svg";
import Hubspot from "../../assets/hubspot.svg";
import IntegrationCard from "../../components/IntegrationsCard";

const IntegrationData = [
  {
    name: "Semrush",
    logoSrc: Semrush,
  },
  {
    name: "Analytics",
    logoSrc: GoogleAnalytics,
  },
  {
    name: "Canva",
    logoSrc: Canva,
  },
  {
    name: "Hubspot",
    logoSrc: Hubspot,
  },
];

const Integration = () => {
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{ px: { xs: 2, sm: 4, md: 8 }, pb: 18, backgroundColor: "#1A1D2F" }}
      >
        <Typography
          variant="h2"
          component="h2"
          align="center"
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            fontWeight: 700,
            lineHeight: { xs: "40px", sm: "48px", md: "56px" },
            color: "white",
          }}
        >
          <CustomText text="GeniusGate Integrates With Your Fav Tools" />
        </Typography>

        <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
          {IntegrationData.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <IntegrationCard name={item.name} logoSrc={item.logoSrc} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Integration;
