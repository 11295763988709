import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Home from "../pages/Home";
import Blogs from "../pages/Blogs";

import ArticleAOV from "../pages/Blogs/BlogArticles/AOV";
import ArticleSequentialTesting from "../pages/Blogs/BlogArticles/SequentialTesting";
import ArticleBestABTools from "../pages/Blogs/BlogArticles/BestABTools";
import ArticleClickstream from "../pages/Blogs/BlogArticles/Clickstream";
import ArticleDigitalEngagement from "../pages/Blogs/BlogArticles/DigitalEngagement";
import ArticleProductContentOptimize from "../pages/Blogs/BlogArticles/ProductContentOptimize";
import ArticlePriceSplit from "../pages/Blogs/BlogArticles/PriceSplit";
import ArticleCustomerEngagement from "../pages/Blogs/BlogArticles/CustomerEngagement";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/learn/",
    element: <Blogs />,
  },
  {
    path: "/learn/aov-meaning/",
    element: <ArticleAOV />,
  },
  {
    path: "/learn/sequential-testing/",
    element: <ArticleSequentialTesting />,
  },
  {
    path: "/learn/best-ab-testing-tools/",
    element: <ArticleBestABTools />,
  },
  {
    path: "/learn/clickstream-data-analysis/",
    element: <ArticleClickstream />,
  },
  {
    path: "/learn/digital-customer-engagement/",
    element: <ArticleDigitalEngagement />,
  },
  {
    path: "/learn/product-content-optimization/",
    element: <ArticleProductContentOptimize />,
  },
  {
    path: "/learn/pricing-split-testing/",
    element: <ArticlePriceSplit />,
  },
  {
    path: "/learn/customer-engagement-analytics/",
    element: <ArticleCustomerEngagement />,
  },
]);
