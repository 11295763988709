import React from "react";

import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Divider,
} from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface FaqCardProps {
  faqsData: {
    question: string;
    answer: string;
  }[];
}

const FaqCard = ({ faqsData }: FaqCardProps) => {
  return (
    <div className="faq_card">
      <Box sx={{ mb: 4 }}>
        <Container>
          {faqsData.map((data, index) => {
            return (
              <Accordion
                key={uuidv4()}
                sx={{ backgroundColor: "rgba(61, 61, 61, 0.2)" }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon sx={{ color: "#ffffff" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="body1" sx={{ color: "#ffffff" }}>
                    {data.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider
                    sx={{
                      height: "0.5px",
                      width: "100%",
                      mb: 1,
                      backgroundImage:
                        "linear-gradient(270deg, rgba(255, 236, 200, 0) 0%, #FFECC8 50%, rgba(255, 236, 200, 0) 100%)",
                    }}
                  />
                  <Typography variant="body1" sx={{ color: "#8F8F8F" }}>
                    {data.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Container>
      </Box>
    </div>
  );
};

export default FaqCard;
