import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Paper,
  Grid,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import FeatureBlogCard from "../../components/FeaturedBlogCard";

import BestABToolsCover from "../../assets/blogs/bestabtools/img_1.jpg";
import SequentialCover from "../../assets/blogs/sequential/img_1.jpg";
import ClickStreamCover from "../../assets/blogs/clickstream/img_4.png";
import DigitalCover from "../../assets/blogs/digital_engagement/img_1.jpg";

import GeniusGateLogo from "../../assets/genius_blog_icon.png";

const featuredBlogData = [
  {
    header: "Best A/B Testing Tools 2024: Expert Reviews",
    coverImage: BestABToolsCover,
    blogDescription:
      "Discover the top A/B testing tools to optimize your digital strategy, enhance user experience, and drive conversions. Learn how to choose the right tool, implement effective tests, and boost your online performance.",
    blogDate: "June 18, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "Alex",
    linkTo: "/learn/best-ab-testing-tools/",
  },
  {
    header: "How to Choose a Sequential Testing Framework",
    coverImage: SequentialCover,
    blogDescription:
      "Unlock faster, data-driven decisions with sequential testing. This guide explores its benefits, modern applications, and best practices to revolutionize your A/B testing strategy. Discover how to achieve optimal results effortlessly.",
    blogDate: "June 18, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "Alex",
    linkTo: "/learn/sequential-testing/",
  },
  {
    header: "How Clickstream Data Analysis Improves Decision-Making",
    coverImage: ClickStreamCover,
    blogDescription:
      "Unlock the secrets of customer behavior with clickstream data analysis. Discover how to transform website interactions into actionable insights that boost engagement, conversions, and growth. Dive into the future of digital success.",
    blogDate: "June 17, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "Alex",
    linkTo: "/learn/clickstream-data-analysis/",
  },
  {
    header: "Digital Customer Engagement: Key Tactics & Examples",
    coverImage: DigitalCover,
    blogDescription:
      "Unlock the secrets of digital customer engagement to boost brand visibility, loyalty, and revenue. Discover key strategies, emerging trends, and real-world success stories to transform your digital interactions and future-proof your business.",
    blogDate: "June 16, 2024",
    writerAvtar: GeniusGateLogo,
    writerName: "Alex",
    linkTo: "/learn/digital-customer-engagement/",
  },
];

const FeaturedBlog = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box sx={{ mt: 15 }}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#1A1D2F",
            backgroundImage:
              "radial-gradient(at center top, rgb(78 92 218 / 25%) 0%, rgba(0, 0, 0, 0) 50%)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Divider
            sx={{
              height: "0.8px",
              width: "100%",
              backgroundImage:
                "linear-gradient(270deg, rgba(255, 236, 200, 0) 0%, #FFECC8 50%, rgba(255, 236, 200, 0) 100%)",
            }}
          />
          <Box sx={{ pt: 5, pb: 5 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: isMobile ? "30px" : "40px",
                fontWeight: 700,
                lineHeight: isMobile ? "36px" : "48px",
                letterSpacing: "-0.5px",
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              Featured Posts
            </Typography>
            <Grid container spacing={2} sx={{ mt: 5 }}>
              {featuredBlogData.map((blog) => (
                <Grid item xs={12} sm={6} md={6} key={uuidv4()}>
                  <FeatureBlogCard
                    header={blog.header}
                    coverImage={blog.coverImage}
                    blogDescription={blog.blogDescription}
                    blogDate={blog.blogDate}
                    writerAvtar={blog.writerAvtar}
                    writerName={blog.writerName}
                    linkTo={blog.linkTo}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default FeaturedBlog;
